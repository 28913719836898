import { Pod } from './pod.model';
import { PortalUser } from './portal-user.model';
import { Customer } from './customer.model';
import { EventLog } from './event-log.model';
import { InvoiceItem } from './invoice-item.model';
import { AppAdvancedSetting } from './app-advanced-setting.model';

export class AppServer {
    serverName: string;
    majorVersion: string;
    minorVersion: string;
    namespace: string;
    domainPostfix: string;
    serverStartDate: Date;
    serverEndDate: Date;
    secureWelcomePage: boolean;
    testVersion: boolean;

    contactPersonName: string;
    contactMail: string;
    contactPhone: string;

    price: number;
    currency: string;
    partner: string;
    notes: string;

    paymentStartDate: Date;
    paymentEndDate: Date;

    serverOwner: PortalUser;
    serverCustomer: Customer;
    serverEvents: EventLog[];
    invoiceItem: InvoiceItem;
    podInfo: Pod;
    appAdvancedSetting: AppAdvancedSetting;
}
