// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    aws: false,
    // dmePortalApi: 'http://localhost:8080/api/',
    dmePortalApi: window.location.origin + "/",
    deployUrl: window.location.origin + "/",
    dmePortalUrl: window.location.origin + "/",
    // dmePortalApi:  "http://localhost:8080/",
    // deployUrl: "http://localhost:8080/",
    // dmePortalUrl: "http://localhost:8080/",
    //dmePortalApi: 'https://k8sportal.test.omscloud.eu/DMEPortal/api/',
//     dmePortalUrl: 'http://localhost:8080/webpack-dev-server/',
    // keycloak: {
    //     // url: 'https://keycloak.test.omscloud.eu/auth',
    //     // realm: 'adminportaltest',
    //     // clientId: 'adminportaltest',
    //     // url: 'http://localhost:8081/auth',
    //     // url: 'http://localhost:8081',
    //     // url: "${KEYCLOAK_URL}",
    //     // // realm: 'test',
    //     // realm: "${KEYCLOAK_REALM}",
    //     // // clientId: 'frontend',
    //     // clientId: "${KEYCLOAK_CLIENT_ID}",
    // }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
