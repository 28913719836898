import { Component, Inject, Input, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Annoucement } from 'src/app/models/annoucement.model';
import { AppComponent, getHash } from '../../app.component';
import {MatGridListModule} from '@angular/material/grid-list';

@Component({
  selector: 'app-announcement-view',
  templateUrl: './announcement-view.component.html',
  styleUrls: ['./announcement-view.component.scss']
})
export class AnnouncementViewComponent implements OnInit {
  colorMapping = {
    SEVERE : "severe",
    WARNING : "warn",
    INFO : "info",
    CONFIG : "config"
  }
  @Input() public data: Array<Annoucement>;
  @Input() public dialogOpened: boolean = false;
  constructor(
    private cookieService: CookieService,

  ) { }

  ngOnInit(): void {
  }

  setReadAnnouncement(item: any) : void {
    this.cookieService.set(AppComponent.cookieAnnouncementPrefix + item.dateTo + "_" + getHash(item), "true", 365);

    this.data = this.data.filter(function( obj ) {
      
      return obj.id !== item.id;
  });
  }


}
