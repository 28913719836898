
import { Severity } from '../services/announcement.service';

export class Annoucement {
    id: number;
    dateFrom: Date;
    dateTo: Date;
    description: string;
    // title: string;
    // severity: Severity;
}
