import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigInitService {

  private CONFIG_API = 'config';

  constructor(private httpClient: HttpClient) {}


  // public getKeycloakConfig(): Observable<string[]> {
  //     return this.httpClient.get<string[]>(environment.dmePortalApi + this.CONFIG_API + '/keycloak');
  // }

  public getKeycloakConfig(): Observable<any>{
    return this.httpClient.get(environment.dmePortalApi + this.CONFIG_API + '/keycloak', { observe: 'response' })
      .pipe(
          catchError((error) => {
              console.log('Error fetching KeyCloak configuration from: ' + environment.dmePortalApi + this.CONFIG_API )
              console.log(error)
              return of(null)
          }),
          mergeMap((response) => {
              if (response && response.body) {
                  return of(response.body);
              } else {
                  return of(null);
              }
          })
      );
  }


}