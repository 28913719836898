import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EventLog } from '../models/event-log.model';
import { formatDate } from '@angular/common';
import { Annoucement } from '../models/annoucement.model';


export enum Severity {
    SEVERE="1", WARNING="2", INFO="3", CONFIG="4"
  }

@Injectable({
  providedIn: 'root'
})
export class AnnoucementService {
    private ANNOUCEMENT_API = 'announcement';
    constructor(private http: HttpClient) { }

    public getPlannedAnnoucements(): Observable<Annoucement[]> {
        return this.http.get<Annoucement[]>(environment.dmePortalApi + this.ANNOUCEMENT_API + '/listPlanned');
    }

    public addNewAnnouncement(announcement: Annoucement) {
        return this.http.post(environment.dmePortalApi + this.ANNOUCEMENT_API + '/add', announcement);
    }

    // public getUnreadAnnouncements(username: string): Observable<Annoucement[]> {
    //     const params = new HttpParams()
    //     .set('username', username)
    //     return this.http.get<Annoucement[]>(environment.dmePortalApi + this.ANNOUCEMENT_API + '/listPlanned', {params});
    // }

}
